.slots-category{
  :global{
    .adm-grid.grid-area{
      --gap-vertical: 0.5rem;
    }
  }
}

.category-tab{
  background: var(--linear-filter);
}